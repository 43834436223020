html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f4f5;
  font-family: "Roboto", sans-serif;
}

.header {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.header img {
  width: 350px;
  height: 81px;
}

.body {
  flex-grow: 1;
  margin-top: 25px;
}

.error {
  color: #f96c6c;
}
